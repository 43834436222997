export class CoWorker {
    /* Basic Model */
    public id?: string;
    public name?: string = '';
    public coworkerNumber?: string;
    public phone?: string;
    public mobile?: string;
    public imagePath?: string;
    public inCaseOfAccident?: string;
    public isActive?: boolean = true;
    public inCaseOfAccidentDetail?: CoWorker[];
    public initials?: string;
    public userId?: string;
    public displayButton?: boolean;
    public status: string;
    public sendProjectAssignEmail: boolean;
    public isSelectedDelete: boolean = false;
    public offerDetail: string;
    public showEconomyDetail: boolean = false;
    public timeoff: number = 0;
    public sendEmailId: string;
    public sendEmailPassword: string;
    public smtp: string;
    public smtpPort?: number;
    public isAddedToMonthlyPriceCalculation: boolean = false;
    public isTrackRoute: boolean=false;
    constructor() { }
}

export class CoworkerEmailSendDTO {
    public id: string;
    public name: string = '';
    public phone: string;
    public mobile: string;
    public email: string;
    public sendEmailId: string;
    public sendEmailPassword: string;
}
export class CoWorkerFull extends CoWorker {
    /*Full Model*/
    public address?: string;
    public zipCode?: string;
    public city?: string;
    public email?: string;
    public coWorkerAs?: string;
    public position?: string;
    public image?: string;
    public dateOfJoininng?: string;
    public userId?: string;
    public sameUser?: boolean;

    public deviceCount: number;
    //addition requierd fields
    public mapLocation?: string;
    public createAutomatic: boolean;
    public countryCode: string;
    public doNotShowOnPlanReminderProj: boolean = false;

    //Remaining  fields start
    public cpr?: string;
    public bankReg?: string;
    public bankAccount?: string;
    public payment?: number;
    public holidays?: number;
    public vacationHolidays?: number;
    public sickDays?: number;
    public isUpdateHolidays: boolean = false;
    public updateHolidays: string = "F";
    public isUpdateVacationHolidays: boolean = false;
    public updateVacationHolidays: string = "F";;
    public telephone?: string;
    public isSeniority: boolean = false;
    public seniority: string = "F";
    public isPrintout: boolean = false;
    public printout: string = "F";
    public color: number;
    public isRemindersSms: boolean = false;
    public remindersSms: string = "F";
    public note?: string;
    public isAdministrationBirthday: boolean = false;
    public administrationBirthday: string = "F";
    public isAdministrationRemindersSms: boolean = false;
    public administrationRemindersSms: string = "F";
    public timeOffHoursPerWeek?: number;
    public multimediaTreasure?: number;
    public companyCarTax?: number;
    public isPayUnderVacation: boolean = false;
    public payUnderVacation: string;
    public birthday?: Date;
    public status: string;
    public accident: string;
    //public picture?: string;
    public type?: number;
    public coworkerInitials: string;
    public coworkerName: string;
    public addedAsCeiling: boolean = false;
    public isLeaveApplyAsApproved: boolean = false;
    public termsID: string = "";
    public coworkerBirthday?: Date;
    public isMultimediataxSetting: boolean = false;
    public multimediataxSetting: string = "F";
    public responsibleManagerIds: string;
    public responsibleManagerIdList:any[]=[];
    public roleID: string = "";

    //End

    public keysJson: string = "";
    constructor() {
        super();
    }
}

export class CoWorkerAllField {
    public imagePath?: string = "";
    public image?: string = "";
}

export class CoWorkerFile {
    public id?: string;
    public coWorkerId?: string;
    public fileId?: string;
    public date?: Date;
    public name?: string;
    public category?: string;
    public path?: string;
    public IsActive?: string;
}

export class CoWorkerDevice {
    public id?: string;
    public coWorkerId?: string;
    public name?: string;
    public udid?: string;
    public deviceType?: string;
    public deviceTypeValue?: string;
    public appleEmailId: string;
}

export class DeleteFutureAllEmployeeOnRequisition {
    public requisitionId: string;
    public coworkerId: string[];
    public startDate: string;
    public endDate: string;
    public projectId: string;
    public planningIds: string;

}

export class DeleteNonWorkingHourForPlanning {
    public coworkerId: string[];
    public status: string;
    public date: any;
    public isDeleteFutureEntry: boolean;
}

export class CoworkerReminderSettings {
    public id: string = "";
    public coworkerID: string = "";
    public planningNotification: boolean = false;
    public planningSMS: boolean = false;
    public planningEmail: boolean = false;
    public contactUsNotification: boolean = false;
    public contactUsSMS: boolean = false;
    public contactUsEmail: boolean = false;
    public coworkerName: string = "";
    public coworkerImage: string = "";
    public offerNotification: boolean = false;
    public offerSMS: boolean = false;
    public offerEmail: boolean = false;
    public projectStatusNotRealNotification: boolean = false;
    public projectStatusNotRealSMS: boolean = false;
    public projectStatusNotRealEmail: boolean = false;
}

export class CoworkerReminderSettingsReq {
    public CoworkerIDS: string = ""
}

export class PayrollEntry {
    public id: string;
    public coworkerID: string = "";
    public date: Date = new Date();
    public numberofHours: number;
    public hourlyRate: number;
    public numberofHolidays: number;
    public description: string;
    public archived: string = "F";
    public koersel: string = "F";
    public gDag: string = "F";
    public holidays: string = "F";
    public salaryAdvance: string = "F";
    public other: string = "F";
    public totalAmount: number;
    public isArchived: boolean = false;
    public isKoersel: boolean = false;
    public isGDag: boolean = false;
    public isHolidays: boolean = false;
    public isSalaryAdvance: boolean = false;
    public isOther: boolean = false;
    public loanEntryType:string ="";
}

export const KeyTypes: any[] = [
    { id: 3, key: 'KeyTypes.value3' },
    { id: 4, key: 'KeyTypes.value4' },
    { id: 1, key: 'KeyTypes.value1' },
    { id: 2, key: 'KeyTypes.value2' },
];

export class Key {
    public keyTypeId: number;
    public quantity: number = 0;
    public keys: any = [];
    public remark: string = "";
}

export class TermsOfEmployment {
    public id: string;
    public designation: string;
    public valideFrom: Date = new Date();
}

export class Rates {
    public id: string;
    public type: string;
    public from: string;
    public to: string;
    public hourlyRate: number;
    public numberofHours: number;
    public weekday: number; 
    public minEmployeeMonth: number; 
    public maxEmployeeMonth: number; 
    public termsofEmploymentID: string; 
    public status?: number;
    public maximumHours: number; 
    public workingHours?: number; 
    public isWorkingShift: boolean = false; 
    public isCalculatedAsExtra: boolean = false;
    public maxLeaveCountDays : number; 
}

export class Assets {
    public id: string;
    public nanolinkDeviceID: string;
    public brand: string;
    public model: string;
    public number: string
    public keywords: string
    public description: string; 
    public groups: number; 
    public labels: string; 
    public imageBase64: string; 
    public imagePath?: string;
    public latitude?: number;
    public longitude?: number;
    public lastServiceDate: Date;
    public nextServiceDate: Date;
    public lastSeenDate: Date;    
}

export class Device {
    public id: string;
    public deviceVID: string;
    public type: number;
    public model: number;
    public description: string; 
    public imageBase64: string; 
    public imagePath?: string;
    public latitude?: number;
    public longitude?: number;
}

export class CoworkerEmployment {
    public id: string;
    public startDate: Date;
    public endDate?: Date;
    public ofSalary: string;
    public basicSalary?: number;
    public basicSalaryHours?: number;
    public personalSupplement?: number; 
    public termsofEmploymentID: string; 
    public type: string; 
    public employeeID: string; 
    public meetingAllowance?: number;
}

export interface NumberOfEmployee {
    value: number;
    viewValue: string;
}