export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Add': 'Added successfully',
            'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'EmailSent': 'Email sent successfully',
            'NORECORD': 'No record found',
        },
        'COWORKER': {
            'TITLE': 'Co-worker',
            'DESC': 'Hello Co-worker!'
        },
        'BUTTONS': {
            'NEWCOWORKER': 'New Co-worker',
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
            'CHANGE_PASSWORD': 'Reset Password',
            'CHANGE_EMAIL': 'Change Email',
            'CREATE_FOLDER': 'Create Folder',
            'DOWNLOAD': 'Download',
            'DELETE': 'Delete',
            'HOUSEKEY': 'House Key',
            'AddEmployement': 'Add Employment'
        },
        'FIELDS': {
            'RegisteredDevice': 'Registered device',
            'Coworkername': 'Co-worker Name*',
            'Number': 'Number*',
            'Address': 'Address',
            'Zipcode': 'Zipcode',
            'City': 'City',
            'Phone': 'Phone',
            'Email': 'Email*',
            'InCaseOfAccident': 'In Case Of Accident',
            'CoworkerAs': 'Co-workerAs*',
            'StartDate': 'Start Date',
            'Position': 'Position',
            'DateOfJoininng': 'Date of Joining',
            'Name': 'Name',
            'DeviceName': 'Device Name',
            'Mobile': 'Mobile',
            'Initials': 'Initials*',
            'Active': 'Set as inactive',
            'AllowAccessToApp': 'Allow access to app',
            'DeviceType': 'Device Type',
            'UDID': 'UDID',
            'No': 'No',
            'AppleEmail': 'Apple Email',
            'SendProjectAssignEmail': 'Send email for new project',
            'OfferDetail': 'Offer detail',
            'ShowEconomyDetail': 'Show financial details',
            'AddedToMonthlyPriceCalculation': 'Employee is incl. in turnover.',

            //Remaining fields
            'Cpr': 'Cpr',
            'BankReg': 'Bank Reg',
            'BankAccount': 'Bank Account',
            'Holidays': 'Holidays',
            'VacationHolidays': 'Vacation Holidays',
            'SickDays': 'Sick Days',
            'AttributeVacationDays': 'Attribute vacation days',
            'AttributeHolidays': 'Attribute holidays',
            'Telephone': 'Telephone',
            'Seniority': 'Seniority',
            'Printout': 'Printout',
            'Color': 'Color',
            'RemindersSms': 'Send reminders in the event of non-reporting',
            'Note': 'Note',
            'AdministrationBirthday': 'Send a message when the employees have a birthday',
            'AdministrationRemindersSms': 'Send a message if the employees have not reported hours (Admin)',
            'Timeoff': 'Timeoff',
            'TimeOffHoursPerWeek': 'Time Off Hours Per Week',
            'MultimediaTreasure': 'Multimedia Treasure',
            'CompanyCarTax': 'Company Car Tax',
            'PayUnderVacation': 'Pay Under Vacation',
            'Birthday': 'Birthday',
            'Status': 'Status',
            'Accident': 'Accident',
            'Picture': 'Picture',
            'Type': 'Type',
            'CoworkerInitials': 'Coworker Initials',
            'CoworkerName': 'Coworker Name',
            'AddedAsLift': 'This employee is lift',
            'DoNotShowOnPlanReminderProj': 'Do not show on planning and project.',
            'Key': "Key",
            'Quantity': "Quantity",
            'Remark': "Remark",
            'SRNO': 'Sr no.',
            'KeyNumber': 'Key number',
            'LeaveApplyAsApproved': 'Application freedom always approved.',
            'SendingAnEmail': 'Sending email',
            'SendingAnEmailPassword': 'Sending An Email Password',
            'Password': 'Password',
            'Payment': 'Payment',
            'TermOfEmployement': 'Terms of Employment',
            'TrackRoute': 'Track driving',
            'FillWorkhours': 'Fill workhours from tracker',
            'EndDate': 'EndDate',
            'Discharge': 'Discharge',
            'BasicSalary': 'Basic salary',
            'BasicSalaryHours': 'Basic salary hours',
            'PersonalSupplement': 'Personal supplement',
            'TermsofEmploymentID': 'Terms of employment ID',
            'MeetingAllowance': 'Meeting allowance',
            'SMTP':'SMTP',
            'SMTPPORT':'SMTP Port Number',
            'ChangePassword': 'Change Password',
            'Setting-Betaling': 'Settings-Payment',
            'LeaveApproveEmployees': 'Leave Approve Employees'
        },
        'Headers': {
            'Ok': 'OK',
            'Cancel': 'Cancel',
            'NEWCOWORKER': 'Create New Co-worker',
            'COWORKER': 'Update Co-worker',
            'NoFileSelected': 'No File Selected',
            'ChooseFile': 'Choose File',
            'FOLDEREMPTY': 'This folder is empty',
            'DEVICELIST': 'Device List',
            'Delete': 'Are you sure you want to delete ',
            'DeleteKey': 'Are you sure you want to delete key',
            'DeleteEmail': 'Are you sure you want to delete sending Email'

        },
        'VALIDATIONS': {
            'NAME': 'Enter Co-worker Name',
            'DeviceName': 'Enter device name',
            'DeviceType': 'Select device type',
            'UDID': 'Enter UDID',
            'LIMIT': 'Limit Exceeded',
            'PHONENO': 'Enter valid number',
            'NUMER': 'Enter Co-worker Number',
            'CoworkerAs': 'Select Co-workerAs',
            'EMAIL': 'Enter Email Address',
            'Initials': 'Initials',
            'NUMBEREXIST': 'Number is already exist',
            'INITIALEXIST': 'Initials is already exist',
            'EMAILEXIST': 'Email is already exist',
            'NOREGISTEREDDEVICE': 'No registered devices',
            'AppleEmail': 'Enter Apple Email',
            'ValidEmail': 'Enter valid email address',
            'PasswordRequired': 'Password is required',
            'CPR': 'Enter Valid CPR',
            'BankReg': 'Enter Valid BankReg',
            'BankAccount': 'Enter Valid BankAccount',
            'DateOfJoininng': 'Enter Valid DateOfJoininng',
            'ValidPhoneRequired': 'Enter Valid Phone',
            'SMTPRequired': 'SMTP is required',
            'SMTPPORTRequired': 'SMTP Port Number is required',
            'SMTPPORTNumberRequired': 'Add Only Number',
        },
        'Tabs': {
            'BasicDetail': 'Basic Details',
            'Files': 'Files',
            'CoworkerOtherFields': 'Coworker other fields',
            'Keys': 'Keys',
            'Note': 'Note',
        },
        'CoworkerDeviceType': {
            'value1': 'IPhone',
            'value2': 'IPad',
        },
        "KeyTypes": {
            "value1": "House Keys",
            "value2": "Shed keys",
            "value3": "Garage Keys",
            "value4": "Mailbox Keys"
        },
        'FILTERS': {
            'All': 'All',
        },
        'TOOTIPORTITLE': {
            'SendSMS': 'Send SMS',
            'SendEmail': 'Send Email',
            'AddEmailAddress': 'Add Email Address',
            'AddPersonDetails': 'Add Person Details',
            'DeletePersonDetails': 'Delete Person Details',
            'Edit': 'Edit',
            'Delete': 'Delete',
        },
        'Roles': [
            { 'key': 'SuperAdmin', 'label': 'SuperAdmin' },
            { 'key': 'Admin', 'label': 'Admin' },
            { 'key': 'Kontor', 'label': 'Office' },
            { 'key': 'Maler', 'label': 'Painter' },
            { 'key': 'Gaest', 'label': 'Guest' },
            { 'key': 'Maler1', 'label': 'Maler1' },
            { 'key': 'Maler2', 'label': 'Maler2' },
            { 'key': 'Maler3', 'label': 'Maler3' },
            { 'key': 'Kontor1', 'label': 'Kontor1' },
            { 'key': 'Kontor2', 'label': 'Kontor2' },
            { 'key': 'Kontor3', 'label': 'Kontor3' },
        ],
        'TITLE': {
            'EmploymentPagination': 'Number of employee employment per page',
          },
    }
};
