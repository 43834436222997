export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'NewMaterial': 'Nyt Materialer',
            'AddMaterial': 'Ønsker du at tilføje materialer?',
            //'DeleteMaterial': 'Vil du slette disse materialer?',
            'DeleteDevice': 'Vil du slette disse this enheds?',
            'Material': 'Materialer',
            'DeleteAlert': 'Er du sikker på du vil slette?',
            'AllRequisitionCoworker': 'Er du sikker på at Vil du gøre det på alle opgaver?',
            'Yes': 'JA',
            'No': 'NEJ',
            'OK': 'Okay',
            'AskForSaveProjectData': 'Vil du gemme disse oplysninger?',
            'CopyCustomerDetails': 'Skal jeg hjælpe med med at hente informationer ?',
            'ChangeStatusToAllRequisition': 'Kan du ændre startet status til alle opgaver?',
            'UnassignCustomerFromInquiry': 'Er du sikker på, at du vil fjerne tildelingen af ​​kunden fra denne forespørgsel ?',
            'MovetoFinishFolder': 'Du sikker på, at du vil flytte denne fil til mappen færdig ?',
            'DeleteProjectFileAlert': 'Er du sikker på du vil slette filen?',
            'DeleteProjectWorkHours': 'Er du sikker på du vil slette Arbejdstimer?',
            'DeleteTodaysNonWorkingHours': 'Er du sikker på, at du vil slette ikke-arbejdstid ?',
            'DeleteFutureNonWorkingHours': 'Ønsker du at slette fremtidige "ikke-arbejdstid" ?',
            'DeleteWorkHoursOnFuture': 'Slet fremtidige time på denne opgave?',
            'DeleteOffer': 'Er du sikker på, at du vil slette dette tilbud?',
            'DeleteAllOffers': 'Er du sikker på, at du vil slette tilbud?',
            'DeleteRoom': 'Er du sikker på, at du vil slette dette rum?',
            'DeleteRoomAlert': 'Tilbudet er oprettet med dette rum, er du sikker på, at du vil slette dette rum, rummedetaljer slettes på tilbud.',
            'ChangeDailySalesCustomer': 'Er du sikker på, at du vil ændre den daglige salgskunde ?',
            'AllReminderDone': 'Vil du gøre alle påmindelser "done" ?',
            'NotDoneAllReminder': 'Vil du fjerne afsluttede påmindelser ?',
            'DeleteSingleReminder': 'Vil du slette denne påmindelse ?',
            'DeleteAllReminder': 'Vil du slette al denne påmindelser ?',
            // 'DeleteAllRooms':'Er du sikker på, at du vil slette alle værelser?'
            'DeleteDiverseMaterial': 'Vil du slette dette materiale?',
            'SaveChanges': 'Vil du gemme de ændringer, du har foretaget?',
            'CopyMaterial': 'Vil du kopiere dette materiale?',
            'CreateCustomer': 'Opret en kunde først',
            'CreateProject': 'oprette et projekt først',
            'DeleteMaterial': 'Vil du slette denne vare?',
            'DeleteProject': 'Vil du slette denne Projekte?',
            'DeleteCustomer': 'Vil du slette denne Kunde?',
            'DeleteMessageForCreatedOffers': 'Du har allerede oprettet et tilbud med disse detaljer, du vil ændre disse detaljer gamle tilbud skal slettes Er du sikker på, at du vil slette det gamle tilbud?',
            'DeleteStatusMessage': 'Vil du slette denne statusmeddelelse?',
            'DeleteRequisitionMessage': 'Vil du slette denne opgave?',
            'InquiryInactiveMessage': 'Vil du gøre Forspørgelsen  inaktiv nå mail er sendt Ja / Nej?',
            'DeleteQuestion': 'Vil du slette dette spørgsmål?',
            'DeleteSupplier': 'Vil du slette denne leverandører ?',
            'DeletePriceDiscount': 'Vil du slette dette element?',
            'EmptyEconomyFields': 'Du kan ikke ændre status til "Klar fakturering" fordi økonomi felter udfyldes ikke.',
            'RoutePlanHeader': 'Du har ikke valgt en rute. Vil du vælge den ?',
            'DeleteCoworker': 'Vil du slette denne Medarbejder ?',
            'UndoChanges': 'Er du sikker på du ønsker at slette pris justering?',
            'DeleteCarInfo': 'Vil du slette denne bil ?',
            'DeleteCompanyInfoTab': 'Vil du slette denne fane ?',
            'DeleteColorCode': 'Vil du slette dette farvekoder ?',
            'CopyMaterialForAllRooms': 'Ønsker du at kopiere dette materiale til alle rum?',
            'MaterialUpdownQuantity': 'vil du øge materialebeholdningen?',
            'UnsavedChanges': 'Du er ikke gemt ændringer - ønsker du at forsætte uden at gemme?',
            'CopyMaterialForSelectedRooms': 'Vil du kopier denne behandling til alle rum?',
            'AutoSaveToCard': 'Vil du gemme tilbuder?',
            'DeleteConformation': 'Vil du slette',
            'DeleteOfferQualityTestAnswer': 'Vil du slette kontrolkema svar',
            'DeleteMessage':'Er du sikker på at du vil slette dette',
            'ViewPersonalPriceMessage':'Vis personale pris på pdf?',
            'MoreThan7Hours':'Vil du bruge mere end 7 timer på fredag?',
            'MoreThan7-5Hours':'Vil du bruge mere end 7,5 timer?',
            'DeleteFeaturePlanning':'Vil du slette planlægning?',
            'MaterialPermission':'Er du sikker på, at du vil have dette projekt i materiale?',
            'CreateAnInvoice': 'Vil du oprette en faktura?',
            'MaterialPriceNot-0': 'Der er et materialer hvor antal er 0,00 ret venligst',
            'CheckWorkHour': 'Der er ikke indberettet timer ønsker du at forsætte',
            'MaterialQuantityNot-0': 'Der er et materialer hvor antal er 0,00 ret venligst',
            'RequisitionNumber': 'Rekvisitionsnummer kræves',
            'CheckworkPlace':'Arbejdssted er ikke udfyldt ønsker du at forsætte',
            'DifferentHour':'Fakturatimer og rapporterede timer er forskellige, er du sikker på, at du vil fortsætte?',
            'DeleteReport': 'Vil du slette denne indberetning?',
            'ViewPersonalPrice-Min-Max-Quantity-Message': 'Vis personale pris, min antal and max antal?',
            'Inactive-Offer-Message': 'Du vil tilbyde Inaktiv?',
            'InvalidMaterialSelected': 'Du har valgt ugyldigt materiale, bedes du kontrollere det og rette dem',
            'ReactiveProjectAndTask': 'Vil du genaktivere projektet og opgaven?',
            'CreateCoworkerWithHelpData': 'Vil du have hjælpedata til at skabe en kollega?',
            'ColorCodeMisMatch':'Farvekoden er anderledes end almindelig, sørg venligst før start.',
        },
        'BUTTONS': {
            'Manually': 'Manuelt'
        }
    }
};
