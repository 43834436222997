import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { SettingsService } from '../../settings.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from "app/main/model/ResponseBase";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { Settings, SettingForUnicontaValue } from "app/main/model/Settings";

@Component({
    selector: 'app-credintial-uniconta',
  templateUrl: './credintial-uniconta.component.html',
    styleUrls: ['./credintial-uniconta.component.scss']
})
export class CredintialUnicontaComponent implements OnInit {
  @Input("commonSettings") commonSettings: Settings;

  showSpinner: boolean = false;
  generalForm: FormGroup;
  settingMasterValues: any[] = [];
  credintialObj: Settings;
  settingValue: SettingForUnicontaValue = new SettingForUnicontaValue();

  projectSettings: any;
  path: string = "";
  ProjectInvoicePathObj: Settings;
  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private settingsService: SettingsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private fb: FormBuilder,) {
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.credintialObj = new Settings();
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    this.generalForm = this.fb.group({
        UserName: [null],
        Password: [null],
        AccessId: [null],
        ComapnyName: [null],
        ComapnyId: [null],
        IsIntegrateUniconta: [false],
        IsGenerateSalesOrders: [false],
        IsGenerateInvoices: [false],
        IsGenerateTXTFile: [false],
        GenerateOrders: ["IsGenerateSalesOrders"],
    });
    this.setSettings();
    this.getSettingsForProjectEconomyDetails();
  }

  setSettings() {
    if (this.commonSettings && this.commonSettings != null) {
      this.credintialObj = this.commonSettings;
        this.settingValue = JSON.parse(this.commonSettings.value);
        if (this.settingValue.IsGenerateSalesOrders) {
            this.settingValue.GenerateOrders = "IsGenerateSalesOrders";
            this.settingValue.IsGenerateInvoices = false;
        } else if (this.settingValue.IsGenerateInvoices) {
            this.settingValue.GenerateOrders = "IsGenerateInvoices";
            this.settingValue.IsGenerateSalesOrders = false;
        } else {
            this.settingValue.GenerateOrders = "IsGenerateSalesOrders";
            this.settingValue.IsGenerateSalesOrders = true;
            this.settingValue.IsGenerateInvoices = false;
        }

      this.generalForm.patchValue(this.settingValue)
      this.changeInLocalStorage(this.credintialObj.id, this.credintialObj)
    }
  }

  onSubmit() {
    this.generalForm.markAllAsTouched();
    if (this.generalForm.valid) {
      this.changeSettings()
    }
  }

  changeSettings() {
      this.showSpinner = true;
      if (this.generalForm.get("GenerateOrders").value == "IsGenerateSalesOrders") {
          this.generalForm.get('IsGenerateSalesOrders').setValue(true);
          this.generalForm.get('IsGenerateInvoices').setValue(false);
      } else if (this.generalForm.get("GenerateOrders").value == "IsGenerateInvoices") {
          this.generalForm.get('IsGenerateSalesOrders').setValue(false);
          this.generalForm.get('IsGenerateInvoices').setValue(true);
      } else {
          this.generalForm.get("GenerateOrders").setValue("IsGenerateSalesOrders");
          this.generalForm.get('IsGenerateSalesOrders').setValue(true);
          this.generalForm.get('IsGenerateInvoices').setValue(false);
      }
    this.credintialObj.value = JSON.stringify(this.generalForm.value);
    this.credintialObj.isDefaultMapper = true;
    this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.credintialObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.credintialObj = response.result;
          this.setSettingsAfterUpdate();
          this.translate.get("MESSAGES.Saved").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => { }
          });
          
          this.changeSettingsForProjectInvoicePath();
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  setSettingsAfterUpdate() {
    this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Credential", this.commonSettings.key2, null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.credintialObj = response.result.myResponse[0];
          this.settingValue = JSON.parse(this.credintialObj.value)
          this.generalForm.patchValue(this.settingValue);
          this.changeInLocalStorage(this.credintialObj.id, this.credintialObj)
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }

  getSettingsForProjectEconomyDetails() {
    this.showSpinner = true;
    this.settingsService.GetSettingsValue<ResponseBase>("Project", "ProjectInvoicePath", null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.ProjectInvoicePathObj = response.result.myResponse[0];
          this.path = response.result.myResponse[0].value;
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  changeSettingsForProjectInvoicePath() {
    this.showSpinner = true;
    this.ProjectInvoicePathObj.value = this.path;
    this.settingsService.UpdateSettingValue<ResponseBase>(this.ProjectInvoicePathObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.ProjectInvoicePathObj = response.result;
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

}
