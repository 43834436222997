export const locale = {
    lang: 'ds',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARD': { 'TITLE': 'Dashboard' },
            'CUSTOMERS': { 'TITLE': 'Debitor' },
            'MATERIALS': { 'TITLE': 'Lager' },
            'PROJECTS': { 'TITLE': 'Projekter' },
            'DOCUMENTS': { 'TITLE': 'Dokumenter' },
            'WORKHOURS': { 'TITLE': 'Arbejdstimer' },
            'MESSAGES': { 'TITLE': 'Beskeder' },
            'COWORKERS': { 'TITLE': 'Medarbejder' },
            'COMPANYINFO': { 'TITLE': 'Firma' },
            'LOGOUT': { 'TITLE': 'Log ud' },
            'OFFER': { 'TITLE': 'Tilbud' },
            'INQUIRY': { 'TITLE': 'Forespørgsler' },
            'REPORT': { 'TITLE': 'Rapport' },
            'RESOURCECALENDAR': { 'TITLE': 'Ressource kalender' },
            'QUICKINVOICE': { 'TITLE': 'Butikssalg' },
            'PLANNING': { 'TITLE': 'Planlægning' },
            'STORESALES': { 'TITLE': 'Butikssalg' },
            'REMINDERS': { 'TITLE': 'Påmindelser' },
            'STATISTICS': { 'TITLE': 'Statestik' },
            'SETUP': { 'TITLE': 'Opsætning' },
            'SUPPLIERS': { 'TITLE': 'Leverandører' },
            'CARINFORMATIONS': { 'TITLE': 'Bil Informationer' },
            'PAYROLL': { 'TITLE': 'Indtast Løn' },
            'TERMSOFEMPLOYMENT': { 'TITLE': 'Ansættelsesvilkår' },
            'SETTINGS': { 'TITLE': 'Indstillinger' },
            'QUALITYTEST': { 'TITLE': 'Kvalitetstest' },
            'TOOLSLADDERS': { 'TITLE': 'Nanolink' },
            'ASSETS': { 'TITLE': 'Enheder' },
            'DEVICE': { 'TITLE': 'Nanno Brikker' },
            'CARINFO': { 'TITLE': 'Bil Info' },
            'LIVEMAP': { 'TITLE': 'Live Kort' },
            'NANOCHIPS': { 'TITLE': 'Nanno Brikker' },
            'ECONOMY': { 'TITLE': 'Økonomi' },
            'SUPPLIERQUICKMATERIAL': { 'TITLE': 'Projekt materiale' },
            'OFFERMATERIALS': { 'TITLE': 'Tilbuds lager' },
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
        'FIELDS': {
            'PaymentMode1': 'Dankort',
            'PaymentMode2': 'Mastercard',
            'PaymentMode3': 'kontant',
            'PaymentMode4': 'Mobilpay',
            'PaymentMode5': 'Mobilepay KK',
            'PaymentMode6': 'Kredit',
            'PaymentMode7': 'Andet',
        }
    }
};
