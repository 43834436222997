export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Add': 'Oprettet',
            'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'EmailSent': 'email sendt succes',
            'NORECORD': 'Ingen rekord fundet',
        },
        'COWORKER': {
            'TITLE': 'Co-worker',
            'DESC': 'Hej Co-worker!'
        },
        'BUTTONS': {
            'NEWCOWORKER': 'Nyt kunde',
            'BACK': 'Tilbage',
            'BTNSAVE': 'Gem',
            'CHANGE_PASSWORD': 'Nulstil kodeord',
            'CHANGE_EMAIL': 'Skift Email',
            'CREATE_FOLDER': 'Opret mappe',
            'DOWNLOAD': 'Hent',
            'DELETE': 'Slet',
            'HOUSEKEY': 'Nøgler ',
            'AddEmployement': 'Tilføje ansættelse'
        },
        'FIELDS': {
            'RegisteredDevice': 'Registreret enhed',
            'Coworkername': 'Medarbejdernavn*',
            'Number': 'Medarbejdernummer*',
            'Address': 'Adresse',
            'Zipcode': 'Postnummer',
            'City': 'By',
            'Phone': 'Telefon',
            'Email': 'Email*',
            'InCaseOfAccident': 'I tilfælde af uheld',
            'CoworkerAs': 'Medarbejdere*',
            'StartDate': 'Start dato',
            'Position': 'Position',
            'DateOfJoininng': 'Ansættelsesdato',
            'Name': 'Navn',
            'DeviceName': 'Enheds Name',
            'Mobile': 'Mobil',
            'Initials': 'Initialer*',
            'Active': 'Indstil som inaktiv',
            'AllowAccessToApp': 'Tillad adgang til app',
            'DeviceType': 'Enheds Type',
            'UDID': 'UDID',
            'No': 'No',
            'AppleEmail': 'Apple Email',
            'SendProjectAssignEmail': 'Send Email ved nyt projekt',
            'OfferDetail': 'Tilbud detalje',
            'ShowEconomyDetail': 'Vis økonomi detal',
            'AddedToMonthlyPriceCalculation': 'Medarbejder er incl. i omsætning',

            //Remaining fields
            'Cpr': 'Cpr',
            'BankReg': 'Bank Reg',
            'BankAccount': 'BankKonto',
            'Holidays': 'Feriedage',
            'VacationHolidays': 'Feriefridage',
            'SickDays': 'Sygedage',
            'AttributeVacationDays': 'Tilskriv feriedage',
            'AttributeHolidays': 'Tilskriv feriefridage',
            'Telephone': 'Telefon',
            'Seniority': 'Anciennitet',
            'Printout': 'Udskrift',
            'Color': 'Farve',
            'RemindersSms': 'Send rykker ved manglende indberetning',
            'Note': 'Notat',
            'AdministrationBirthday': 'Send besked når de ansatte har fødselsdag',
            'AdministrationRemindersSms': 'Send besked hvis de ansatte ikke har indberettet timer (Admin)',
            'Timeoff': 'Afspadsering',
            'TimeOffHoursPerWeek': 'AfspadseringTimerPrUge',
            'MultimediaTreasure': 'Multimedieskat',
            'CompanyCarTax': 'Firmabilskat',
            'PayUnderVacation': 'Løn under ferie',
            'Birthday': 'Fødselsdag',
            'Status': 'Status',
            'Accident': 'Ulykke',
            'Picture': 'Billede',
            'Type': 'Type',
            'CoworkerInitials': 'Medarbejder Initialer',
            'CoworkerName': 'Medarbejder Navn',
            'AddedAsLift': 'Denne medarbejder er lift',
            'DoNotShowOnPlanReminderProj': 'Vis ikke på planlægning og projekt.',
            'Key': "Nøgler",
            'Quantity': "Antal",
            'Remark': "Bemærkning",
            'SRNO': 'Sr nr',
            'KeyNumber': 'Nøgler nummer',
            'LeaveApplyAsApproved': 'Ansøgning frihed altid godkendt.',
            'SendingAnEmail': 'Afsender e-mail',
            'SendingAnEmailPassword': 'Afsendelse af en e-mail-adgangskode',
            'Password': 'Adgangskode',
            'TermOfEmployement': 'Ansættelsesvilkår',
            'TrackRoute': 'Track kørsel',
            'Payment': 'Betaling',
            'FillWorkhours': 'Udfyld arbejdstimer fra tracker',
            'EndDate': 'SlutDato',
            'Discharge': 'Afloenning',
            'BasicSalary': 'Grundløn',
            'BasicSalaryHours': 'Grundløn timer',
            'PersonalSupplement': 'Personligt tillæg',
            'TermsofEmploymentID': 'Vilkår for ansættelses-ID',
            'MeetingAllowance': 'Mødegodtgørelse',
            'SMTP':'SMTP',
            'SMTPPORT':'SMTP Portnummer',
            'ChangePassword': 'Skift kodeord',
            'Setting-Betaling': 'Multimedieskat Ja/Nej',
            'LeaveApproveEmployees': 'Forlad godkend medarbejdere'
        },
        'Headers': {
            'Ok': 'Gem',
            'Cancel': 'Gå tilbage',
            'NEWCOWORKER': 'Opret ny Medarbejder',
            'COWORKER': 'Medarbejder',
            'NoFileSelected': 'Ingen fil valgt',
            'ChooseFile': 'Vælg fil',
            'FOLDEREMPTY': 'Denne folder er tom',
            'DEVICELIST': 'Enheds Liste',
            'Delete': 'Er du sikker på, at du vil slette ',
            'DeleteKey': 'Er du sikker på, at du vil slette nøgler',
            'DeleteEmail': 'Er du sikker på, at du vil slette afsendende e-mail'
        },
        'VALIDATIONS': {
            'NAME': 'Medarbejdernavn påkrævet',
            'DeviceName': 'Indtast enhedsnavn',
            'DeviceType': 'Vælg enhedstype',
            'UDID': 'Indtast UDID',
            'LIMIT': 'Grænse overskredet',
            'PHONENO': 'Indtast gyldigt nummer',
            'NUMER': 'Medarbejdernummer påkrævet',
            'CoworkerAs': 'Vælg kollega as påkrævet',
            'Initials': 'Initialer påkrævet',
            'EMAIL': 'E-mail adresse påkrævet',
            'NUMBEREXIST': 'Nummeret eksisterer allerede',
            'INITIALEXIST': 'Initialer findes allerede',
            'EMAILEXIST': 'Email findes allerede',
            'NOREGISTEREDDEVICE': 'Ingen registrerede enheder',
            'AppleEmail': 'Indtast Apple Email',
            'ValidEmail':'Indtast gyldig e-mail adresse påkrævet',
            'PasswordRequired': 'Adgangskode er påkrævet',
            'CPR': 'Indsæt gyldig CPR påkrævet',
            'BankReg': 'Indsæt gyldig BankReg påkrævet',
            'BankAccount': 'Indsæt gyldig BankKonto påkrævet',
            'DateOfJoininng': 'Indsæt gyldig Ansættelsesdato',
            'ValidPhoneRequired': 'Indtast gyldig telefon',
            'SMTPRequired': 'SMTP er påkrævet',
            'SMTPPORTRequired': 'SMTP Portnummer er påkrævet',
            'SMTPPORTNumberRequired':'Tilføj kun nummer'
        },
        'Tabs': {
            'BasicDetail': 'Oplysninger',
            'Files': 'Filer',
            'CoworkerOtherFields': 'Medarbejder andre felter',
            'Keys': 'Nøgle',
            'Note': 'Bemærk',
        },
        'CoworkerDeviceType': {
            'value1': 'IPhone ',
            'value2': 'IPad',
        },
        "KeyTypes": {
            "value1": "Husnøgler",
            "value2": "Skur nøgler",
            "value3": "Garage nøgler",
            "value4": "Postkasse nøgler"
        },
        'FILTERS': {
            'All': 'Alle',
        },
        'TOOTIPORTITLE': {
            'SendSMS': 'Send SMS',
            'SendEmail': 'Send E-mail',
            'AddEmailAddress':'Tilføj E-mail Adresse',
            'AddPersonDetails':'Tilføj Person Detaljer',
            'DeletePersonDetails':'Slet Person Detaljer',
            'Edit':'Redigere',
            'Delete':'Slet',

        },
        'Roles': [
            { 'key': 'SuperAdmin', 'label': 'SuperAdmin' },
            { 'key': 'Admin', 'label': 'Admin' },
            { 'key': 'Kontor', 'label': 'Kontor' },
            { 'key': 'Maler', 'label': 'Maler' },
            { 'key': 'Gaest', 'label': 'Gæst' },
            { 'key': 'Maler1', 'label': 'Maler1' },
            { 'key': 'Maler2', 'label': 'Maler2' },
            { 'key': 'Maler3', 'label': 'Maler3' },
            { 'key': 'Kontor1', 'label': 'Kontor1' },
            { 'key': 'Kontor2', 'label': 'Kontor2' },
            { 'key': 'Kontor3', 'label': 'Kontor3' },
        ],
        'TITLE': {
            'EmploymentPagination': 'Antal Medarbejderansættelse pr. side',
        },
    }
};
