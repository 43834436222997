import { Component, OnInit, ElementRef, NgZone, ViewChild, Optional, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CoWorkersService } from './../coWorkers.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/services/auth.service';
import { CoWorkerFull, CoWorkerFile, CoWorkerAllField, KeyTypes } from 'app/main/model/CoWorker';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestCommonBase, SendEmailPasswordData } from 'app/main/model/RequestBase';
import { IsEmailExistValidationForCoWorker, IsInitialExistValidationForCoWorker, GenerateRandomNumber, IsExistValidationForCoWorker, IsNumber, spaceOnlyValidator, CustomErrorStateMatcher } from 'app/core/Validators/validation';
import { MapsAPILoader } from '@agm/core';
import { MatDialog, DateAdapter, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { ChangeEmailComponent } from 'app/main/pages/change-email/change-email.component';
import { TitleService } from 'app/core/services/title.service';
import { DatePipe } from '@angular/common';
import { FormService } from 'app/core/services/form.service';
import { FileUpload } from 'app/main/model/FileUpload';
import { RequestCoWorker, SMSModules, RequestTearmsBase } from 'app/main/model/RequestBase';
import { DeleteAlertModalComponent } from 'app/main/pages/delete-alert-modal/delete-alert-modal.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { CreateFolder, SearchFolder, ListFolder, FilesObj, FolderListNavigationDTO } from 'app/main/model/DropBox';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { EmailSendingParam } from 'app/main/model/common';
import { SMSModuleEnum } from 'app/core/enum/sms-module.enum';
import { SendSMSComponent } from 'app/main/shared-component/send-sms/send-sms.component';
import { KeyComponent } from '../component/key/key.components';
import { SendEmailPasswordComponent } from '../component/send-email-password/send-email-password.component';
import { TermsOfEmploymentService } from 'app/main/pages/terms-of-employment/terms-of-employment.service';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { NewCoworkerEmploymentComponent } from '../component/new-coworker-employment/new-coworker-employment.component';

import * as moment from 'moment';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { DriveModuleEnum } from '../../../../core/enum/drive-module.enum';
import { SettingsService } from '../../settings/settings.service';

@Component({
    selector: 'app-new-co-worker',
    templateUrl: './new-co-worker.component.html',
    styleUrls: ['./new-co-worker.component.scss']
})

export class NewCoWorkerComponent implements OnInit {
    @ViewChild("search", { static: true }) public searchElementRef: ElementRef;
    isFile: boolean = false;
    IsDisplay: boolean;
    roles: any;
    //savedata: any;
    coworkerForm: FormGroup;
    // coworkerAdditionForm: FormGroup;
    roleName: string;
    host: string = '';
    CoWorkerObject: CoWorkerFull;
    StaticCoWorkerObject: any;
    isLoad: boolean = false;
    isEdit: boolean = false;
    autocomplete: any;
    objAddressEdit: any = {};
    showSpinner: boolean = false;
    latitude: number;
    longitude: number;
    public zoom: number;
    onlyAdmin: boolean = false;
    InCaseOfAccidentDetails: CoWorkerFull[] = [];
    files: CoWorkerFile[] = [];
    selectedIndex: number = 0;
    coWokers = [];
    displayDelete: boolean = false;
    editableCoworkerID: string = "";
    loginCoworkerID: string = "";
    //dropbox declaration start
    res: any;
    createFolder: CreateFolder;
    searchFolder: SearchFolder;
    listFolder: ListFolder
    renterForm: FormGroup;
    fileObjList: Array<FilesObj>;
    checkedList: any;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    CoWorkerAllFieldObject: CoWorkerAllField = new CoWorkerAllField;
    typeList = [];
    statusList = [];
    coworkerKeyList = [];
    keyTypeList = KeyTypes;

    termsOfEmploymentList: any[] = [];
    requestBase: any;
    coWorkerList: CoWorkerFull[];
    currentId: any;
    previousId: any;
    nextId: any;
    tempCoworkerId: any;
    //dropbox declaration end

    isSubmitted: boolean = false;
    esMatcher = new CustomErrorStateMatcher();
    coworkerEmploymentList: any[] = [];
    requestCoworkerEmployment: RequestCommonBase = new RequestCommonBase();
    pagination: any;
    limitNumber: any;
    displayPagination: boolean = false;
    roleClaims: any;
    hasChangeRolePermision: boolean = false;
    hasViewFilesPermision: boolean = false;
    hasViewOtherFieldPermision: boolean = false;
    hasViewKeyPermision: boolean = false;
    hasCPRViewPermision: boolean = false;
    SendEmailPasswordData = new SendEmailPasswordData;
    localResponseModel: any;
    noteDescription: string = "";
    IsRedirectToPopup: boolean = false;
    ModuleDefaultSetting: any = {};
    constructor(private translationLoader: FuseTranslationLoaderService, private coWorkersService: CoWorkersService, private _route: ActivatedRoute,
        private fb: FormBuilder, private _router: Router, private authService: AuthService, private elem: ElementRef, private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone, public dialog: MatDialog, private titleService: TitleService,
        private adapter: DateAdapter<any>,
        private settingsService: SettingsService,
        @Optional() public dialogRef: MatDialogRef<NewCoWorkerComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private datePipe: DatePipe,
        public _ngZone: NgZone,
        private formService: FormService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private termsOfEmploymentService: TermsOfEmploymentService,
        private commonService: CommonService,
        private projectService: ProjectService,
        private dropboxFilesService: DropboxFilesService,) {
        this.titleService.SetTitle("TITLE_COWORKER");
        //this.roles = ['Admin', 'Kontor', 'Maler', 'Gaest'];
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
        this.AddInCaseOfAccidentDetail();
        window["coWorkerComponent"] = { component: this, zone: this._ngZone };

    }

    getRoleArray() {
        //this.translate.get("Roles").subscribe({
        //    next: (res) => {
        //        this.roles = res;
        //        this.roles.sort(function (a, b) {
        //            return a.label.localeCompare(b.label);
        //        });
        //    }, error: err => { }
        //});
        this.showSpinner = true;
        this.projectService.GetUserRoleList().subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.roles = response.result.myResponse;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.Change_Role).subscribe(hasClaim => {
            if (hasClaim)
                this.hasChangeRolePermision = hasClaim
            else
                this.hasChangeRolePermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Coworker_View_Files).subscribe(hasClaim => {
            if (hasClaim)
                this.hasViewFilesPermision = hasClaim
            else
                this.hasViewFilesPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Coworker_View_Other_Fields).subscribe(hasClaim => {
            if (hasClaim)
                this.hasViewOtherFieldPermision = hasClaim
            else
                this.hasViewOtherFieldPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Coworker_View_Keys).subscribe(hasClaim => {
            if (hasClaim)
                this.hasViewKeyPermision = hasClaim
            else
                this.hasViewKeyPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Coworker_CPR_View).subscribe(hasClaim => {
            if (hasClaim)
                this.hasCPRViewPermision = hasClaim
            else
                this.hasCPRViewPermision = false;
        });
        this.getRoleArray();

        this.fileObjList = Array<FilesObj>();
        this.checkedList = new Array();
        this.host = environment.url;
        this.roleName = this.authService.getUserRole();
        this.loginCoworkerID = this.authService.getCoWorkerId();
        
        if (this.data) {
            this.IsRedirectToPopup = true;
            this.isLoad = true;
            this.coworkerForm = this.fb.group({
                position: [{ value: 'position', disabled: true }],
                name: ['', Validators.compose([Validators.maxLength(50), spaceOnlyValidator.bind(this)])],
                zipCode: ['', Validators.maxLength(10)],
                city: ['', Validators.maxLength(50)],
                phone: ['', Validators.maxLength(20)],
                address: ['', Validators.maxLength(40)],
                inCaseOfAccident: [null],
                coWorkerAs: [''],
                roleID: [''],
                dateOfJoininng: [null],
                CountryCode: [null],
                OfferDetail: [null],
                sendEmailId: [{ value: null, disabled: true }, []],
                sendEmailPassword: [null],
                smtp: [null],
                smtpPort: [null],
                cpr: [null],
                bankReg: [null],
                bankAccount: [null],
                payment: [null],
                termsID: [null],
                coworkerNumber: [null],
                initial: [null],
                email: [null],
            });
            this.getDefaultValueSettingCoworker();
        }
        else {
            this.coworkerForm = this.fb.group({
                position: [{ value: 'position', disabled: true }, !Validators.required],
                name: ['', Validators.compose([Validators.required, Validators.maxLength(50), spaceOnlyValidator.bind(this)])],
                zipCode: ['', Validators.maxLength(10)],
                city: ['', Validators.maxLength(50)],
                phone: ['', Validators.maxLength(20)],
                address: ['', Validators.maxLength(40)],
                inCaseOfAccident: ['', Validators.maxLength(5000)],
                coWorkerAs: ['', Validators.required],
                roleID: ['', Validators.required],
                //picture: [null, !Validators.required],
                dateOfJoininng: [null, Validators.required],
                CountryCode: [null],
                OfferDetail: [null],
                //sendEmailId: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
                sendEmailId: [{ value: null, disabled: true }, []],
                sendEmailPassword: [null],
                smtp: [null],
                smtpPort: [null],
                cpr: [null],
                bankReg: [null, Validators.required],
                bankAccount: [null, Validators.required],
                payment: [null],
                termsID: [null, Validators.required],
            });

            this._route.params.subscribe(params => {
                this.editableCoworkerID = params['id'];
                this.tempCoworkerId = this.editableCoworkerID;
                if (this.editableCoworkerID === undefined) {
                    this.CoWorkerObject = new CoWorkerFull();
                    this.IsDisplay = true;
                    this.isLoad = true;
                    this.coworkerForm.get('dateOfJoininng').setValue(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
                    this.CoWorkerObject.dateOfJoininng = this.datePipe.transform(new Date(), "yyyy-MM-dd");
                    this.coworkerForm.addControl('coworkerNumber', new FormControl(null, [Validators.required, Validators.maxLength(10)], [IsExistValidationForCoWorker(this.coWorkersService)])),
                        this.coworkerForm.addControl('email', new FormControl('', [Validators.required, , spaceOnlyValidator.bind(this), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)], [IsEmailExistValidationForCoWorker(this.coWorkersService)])),
                        this.coworkerForm.addControl('initial', new FormControl(null, [Validators.required, spaceOnlyValidator.bind(this), Validators.maxLength(5)], [IsInitialExistValidationForCoWorker(this.coWorkersService)]));
                }
                else {
                    this.displayDelete = true;
                    this.IsDisplay = false;
                    this.coworkerForm.addControl('coworkerNumber', new FormControl({ value: 'number', disabled: false }, [])),
                        this.coworkerForm.addControl('email', new FormControl({ value: 'email', disabled: true }, [])),
                        this.coworkerForm.addControl('initial', new FormControl(null, [Validators.required, spaceOnlyValidator.bind(this), Validators.maxLength(5)]));
                    this.Edit(this.editableCoworkerID);
                    this.getNotetabData();
                }

            });

            if (this._router.url === '/coWorkers/add-with-help') {
                this.getDefaultValueSettingCoworker();
                this.IsRedirectToPopup = false;
            }

            if (this.hasCPRViewPermision) {
                this.coworkerForm.get('cpr').setValidators([
                    Validators.required, spaceOnlyValidator.bind(this)
                ]);
            }
        }
      
        this.adapter.setLocale('da');
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        this.getCoWorkers(coWorker);

        this.requestBase = new RequestTearmsBase();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.getTermsOfEmploymentList();
        
    }

    ChangeImage(event) {
        this.CoWorkerObject.image = event;
    }

    getDefaultValueSettingCoworker() {
        this.showSpinner = true;
        this.settingsService.GetModuleDefaultValueSetting<ResponseBase>("","coworker")
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false
                    this.CoWorkerObject = new CoWorkerFull();
                    this.CoWorkerObject = JSON.parse(response.result.json);
                    this.CoWorkerObject.id = null;
                    this.coworkerForm.patchValue(this.CoWorkerObject);
                    this.ModuleDefaultSetting = response.result;
                    this.coworkerForm.get('dateOfJoininng').setValue(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
                    this.CoWorkerObject.dateOfJoininng = this.datePipe.transform(new Date(), "yyyy-MM-dd");
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }

    save(event) {
        this.isSubmitted = true;
        this.esMatcher.isSubmitted = this.isSubmitted;
        var role = this.roles.find(o => o.id == this.CoWorkerObject.roleID);
        if (role) {
            this.coworkerForm.get("coWorkerAs").setValue(role.name);
        }
        this.formService.markFormGroupTouched(this.coworkerForm);
        if (this.coworkerForm.valid && this.esMatcher.isValid) {
            if (this.IsRedirectToPopup) {
                this.UpdateModuleDefaultValueSettings();
            }
            else {
                event.preventDefault();
                if (!this.CoWorkerObject.id) {
                    if (!this.CoWorkerObject.isAddedToMonthlyPriceCalculation) {
                        var msg = "";
                        this.translate.get("FIELDS.AddedToMonthlyPriceCalculation").subscribe({
                            next: (res) => {
                                msg = res;
                            }, error: err => { }
                        });
                        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                            height: '200px',
                            data: { Header: msg },
                            disableClose: true
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            if (result)
                                this.CoWorkerObject.isAddedToMonthlyPriceCalculation = true;
                            else
                                this.CoWorkerObject.isAddedToMonthlyPriceCalculation = false;
                            this.Add();
                        });
                    }
                    else
                        this.Add();
                }
                else {
                    this.Update();
                }
            }
        }
    }

    UpdateModuleDefaultValueSettings() {
        this.showSpinner = true;
        this.CoWorkerObject.id = null;
        this.ModuleDefaultSetting.json = JSON.stringify(this.CoWorkerObject);
        this.settingsService.UpdateModuleDefaultValueSetting<ResponseBase>(this.ModuleDefaultSetting.id, this.ModuleDefaultSetting)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    error: err => {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => { this.showSpinner = false },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    formButton() {
        document.getElementById("formButton").click();
    }

    Edit(id) {
        this.isEdit = true;
        this.showSpinner = true;
        this.coWorkersService.GetSingle<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.CoWorkerObject = response.result;

                    this.objAddressEdit = {
                        'address': this.CoWorkerObject.address,
                        'city': this.CoWorkerObject.city,
                        'pinCode': this.CoWorkerObject.zipCode,
                        'position': this.CoWorkerObject.position,
                        'mapLocation': this.CoWorkerObject.mapLocation,
                        'IsEdit': true
                    }
                    var Id = this.authService.getUserId();
                    if (Id == this.CoWorkerObject.userId) {
                        this.CoWorkerObject.sameUser = true;
                        //this.savedata = true;
                    }
                    this.isLoad = true;
                    if (this.CoWorkerObject.inCaseOfAccident) {
                        this.InCaseOfAccidentDetails = JSON.parse(this.CoWorkerObject.inCaseOfAccident.replace(/'/g, '"'));
                    }
                    if (this.InCaseOfAccidentDetails) {
                        //this.AddInCaseOfAccidentDetail();
                    }
                    else if (this.InCaseOfAccidentDetails.length == 0)
                        this.AddInCaseOfAccidentDetail();
                    else {
                        this.InCaseOfAccidentDetails = [];
                        this.AddInCaseOfAccidentDetail();
                    }
                    this.showSpinner = false;
                    this.StaticCoWorkerObject = Object.assign({}, this.CoWorkerObject);
                    this.storeFileFolderPathData();
                    if (this.CoWorkerObject.keysJson) {
                        this.coworkerKeyList = JSON.parse(this.CoWorkerObject.keysJson);
                    }
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }

    Add() {
        this.showSpinner = true;
        this.CoWorkerObject.dateOfJoininng = moment(this.CoWorkerObject.dateOfJoininng).format("YYYY-MM-DD");
        this.CoWorkerObject.inCaseOfAccident = this.GetInCaseOfAccidentDetail();
        this.StaticCoWorkerObject = Object.assign({}, this.CoWorkerObject);
        if (this.CoWorkerObject.cpr && this.CoWorkerObject.cpr.length >= 6) {
            var arrayObj = this.CoWorkerObject.cpr.split('-');
            if (arrayObj.length > 0) {
                this.CoWorkerObject.coworkerBirthday = new Date(moment(arrayObj[0], "DDMMYY")["_d"]);
                this.CoWorkerObject.coworkerBirthday = new Date(moment(this.CoWorkerObject.coworkerBirthday).format("YYYY-MM-DD"));
            }
        }
        this.coWorkersService.Add<ResponseBase>(this.CoWorkerObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.storeFileFolderPathData();
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    error: err => {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => { this.showSpinner = false },
                complete: () => {
                    this._router.navigateByUrl('coWorkers');
                    this.showSpinner = false;
                }
            });
    }

    Update() {
        this.showSpinner = true;
        this.CoWorkerObject.dateOfJoininng = moment(this.CoWorkerObject.dateOfJoininng).format("YYYY-MM-DD");
        this.CoWorkerObject.inCaseOfAccident = this.GetInCaseOfAccidentDetail();
        this.StaticCoWorkerObject = Object.assign({}, this.CoWorkerObject);
        if (this.CoWorkerObject.cpr && this.CoWorkerObject.cpr.length >= 6) {
            var arrayObj = this.CoWorkerObject.cpr.split('-');
            if (arrayObj.length > 0) {
                this.CoWorkerObject.coworkerBirthday = new Date(moment(arrayObj[0], "DDMMYY")["_d"]);
                this.CoWorkerObject.coworkerBirthday = new Date(moment(this.CoWorkerObject.coworkerBirthday).format("YYYY-MM-DD"));
            }
        }
        this.coWorkersService.Update<ResponseBase>(this.CoWorkerObject.id, this.CoWorkerObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.CoWorkerObject = response.result;
                    this.localResponseModel = JSON.parse(localStorage.getItem('LocalResponseModel'));
                    let coworker = this.localResponseModel.Coworkers.find(coworker => coworker.id === this.CoWorkerObject.id);
                    if (coworker) {
                        coworker.name = this.CoWorkerObject.name;
                        coworker.email = this.CoWorkerObject.email;
                        localStorage.setItem('LocalResponseModel', JSON.stringify(this.localResponseModel));
                    }
                    var Id = this.authService.getUserId();
                    if (Id == this.CoWorkerObject.userId) {
                        this.coWorkersService.SetUserIcon(this.CoWorkerObject.imagePath);
                    }
                    //  this.coWorkersService.setResponseForCoworker(this.coWokers);
                    this.showSpinner = false;
                    this.storeFileFolderPathData();
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    if (this.editableCoworkerID != this.tempCoworkerId)
                        this._router.navigate(['/coWorkers/edit', this.tempCoworkerId]);
                },
                error: err => {
                    this.showSpinner = false;
                    if (err.status != 409) {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                complete: () => {
                    //this._router.navigateByUrl('coWorkers');
                }
            });
    }

    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.GetCoworker<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    //this.coWokers = response.result;
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                    //this.SecurityOfficers = [];
                }
            },
                error => (err) => {
                    this.coWokers = [];
                    //this.SecurityOfficers = [];
                },
                () => {
                });
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = response;
        }
        else {
            this.coWokers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        this.getSetNextPreviousId();
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    emailCoworker() {
        var emailSendingParam = new EmailSendingParam();
        emailSendingParam.fromName = this.authService.getUserName();
        //emailSendingParam.from = this.authService.getUserEmail();
        // emailSendingParam.from = "post@vibesmalerfirma.dk";
        emailSendingParam.subject = "";
        emailSendingParam.coworkerList = this.coWokers;
        emailSendingParam.selectedCoworker = this.CoWorkerObject.email;
        emailSendingParam.moduleName = "Coworker";
        emailSendingParam.moduleID = this.editableCoworkerID;
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.coWorkersService.SendEmail<ResponseBase>(result)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.EmailSent").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.commonService.checkEmailIsToBeSent();
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });

                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        });
    }

    isNumber(evt) {
        return IsNumber(evt);
    }

    back() {
        if (this.IsRedirectToPopup) {
            this.dialogRef.close();
        }
        else {
            this._router.navigateByUrl('coWorkers');
        }
    }

    AddInCaseOfAccidentDetail() {
        var coWorker = new CoWorkerFull();
        coWorker.id = GenerateRandomNumber().toString();
        if (this.InCaseOfAccidentDetails.length < 2)
            this.InCaseOfAccidentDetails.push(coWorker);
    }

    RemoveInCaseOfAccidentDetail(index, name) {
        var deleteMessage = "";
        this.translate.get("Headers.Delete").subscribe({
            next: (res) => {
                deleteMessage = res;
            },
            error: err => {
            }
        });
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: deleteMessage + name + "?" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.InCaseOfAccidentDetails.splice(index, 1);
            }
        });
    }

    AddFormControl(index, objValue) {
        for (var obj in Object.keys(objValue))
            this.coworkerForm.addControl(obj + index, new FormControl(null, []));
    }

    GetInCaseOfAccidentDetail() {
        return JSON.stringify(this.InCaseOfAccidentDetails);
    }

    ChangePassword(userId) {
        this.coWorkersService.ResetPassword(userId).then(result => {
            this.translate.get("MESSAGES.Updated").subscribe({
                next: (res) => {
                    this.notificationsService.create(res);
                },
                error: err => {
                }
            });
        }).catch(err => {
            this.translate.get("MESSAGES.Failed").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.commonService.playFailedTone();
                },
                error: err => {
                }
            });
        });
    }

    receiveAddressObject($event) {
        this.CoWorkerObject.address = $event.address;
        this.CoWorkerObject.city = $event.city;
        this.CoWorkerObject.zipCode = $event.pinCode;
        this.CoWorkerObject.position = $event.position;
    }

    openDialog(id) {
        const dialogRef = this.dialog.open(ChangeEmailComponent, {
            height: '250px',
            width: '400px',
            data: id
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.CoWorkerObject.email = result;
            }
        });
    }

    onSetActive(IsActive) {
        this.CoWorkerObject.isActive = IsActive;
    }

    sendSMS() {
        var moduleObj = new SMSModules()
        moduleObj.name = this.CoWorkerObject.name;
        moduleObj.moduleID = this.CoWorkerObject.id;
        moduleObj.mobile = this.CoWorkerObject.phone;
        moduleObj.flag = SMSModuleEnum.Coworker;
        moduleObj.countryCode = this.CoWorkerObject.countryCode;
        const dialogRef = this.dialog.open(SendSMSComponent, {
            height: '350px',
            width: '800px',
            data: moduleObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }


    AddFile(event) {
        this.showSpinner = true;
        var fileUpload = new FileUpload();
        fileUpload.name = event.name;
        fileUpload.type = event.type;
        fileUpload.value = event.value;
        this.coWorkersService.AddFileToCoWorker(this.CoWorkerObject.id, fileUpload)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response)
                        this.files.push(response.result);
                    this.showSpinner = false;
                },
                error: err => {
                },
                complete: () => { }
            });
    }

    RemoveFile(event) {
        const dialogRef = this.dialog.open(DeleteAlertModalComponent, {
            height: '200px',
            data: event
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.coWorkersService.RemoveFileFromCoWorker<ResponseBase>(this.CoWorkerObject.id, event)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.files.splice(this.files.findIndex(f => f.id == event), 1);
                            this.showSpinner = false;
                        },
                        error: err => { },
                        complete: () => { }
                    });
            }
        });
    }

    TabChange(event) {
        this.selectedIndex = event.index;
        if (event.index == 0) {
            if (this.editableCoworkerID)
                this.Edit(this.editableCoworkerID);
            this.requestBase = new RequestTearmsBase();
            this.requestBase.Index = 1;
            this.requestBase.Limit = 100;
            this.getTermsOfEmploymentList();
        }

        if (event.index == 2 && this.CoWorkerObject.id) {
            this.getAllCoWorkerFields();
            this.requestCoworkerEmployment.Index = 1;
            this.requestCoworkerEmployment.Limit = 100;
            this.requestCoworkerEmployment.CoworkerID = this.editableCoworkerID;
            this.getCoworkerEmploymentList();
        }
        
        if(event.index == 4 && this.CoWorkerObject.id)
            this.getNotetabData();
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData.push(
            { name: 'Coworker', isDisplay: false },
            { name: this.CoWorkerObject.coworkerNumber, isDisplay: true }
        );
    }

    IsFileExist(flag) {
        this.isFile = flag;
    }

    onClickEconomyDetail(flag) {
        this.CoWorkerObject.showEconomyDetail = !flag;
    }

    onKeyPressCheckNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 44 && charCode != 46) {
            return false;
        }
        return true;
    }

    onUpdateHolidays() {
        if (this.CoWorkerObject.isUpdateHolidays)
            this.CoWorkerObject.updateHolidays = 'T'
        else
            this.CoWorkerObject.updateHolidays = 'F'
    }

    onUpdateVacationHolidays() {
        if (this.CoWorkerObject.isUpdateVacationHolidays)
            this.CoWorkerObject.updateVacationHolidays = 'T'
        else
            this.CoWorkerObject.updateVacationHolidays = 'F'
    }

    onSeniority() {
        if (this.CoWorkerObject.isSeniority)
            this.CoWorkerObject.seniority = 'T'
        else
            this.CoWorkerObject.seniority = 'F'
    }

    onPrintout() {
        if (this.CoWorkerObject.isPrintout)
            this.CoWorkerObject.printout = 'T'
        else
            this.CoWorkerObject.printout = 'F'
    }

    onRemindersSms() {
        if (this.CoWorkerObject.isRemindersSms)
            this.CoWorkerObject.remindersSms = 'T'
        else
            this.CoWorkerObject.remindersSms = 'F'
    }

    onAdministrationBirthday() {
        if (this.CoWorkerObject.isAdministrationBirthday)
            this.CoWorkerObject.administrationBirthday = 'T'
        else
            this.CoWorkerObject.administrationBirthday = 'F'
    }

    onAdministrationRemindersSms() {
        if (this.CoWorkerObject.isAdministrationRemindersSms)
            this.CoWorkerObject.administrationRemindersSms = 'T'
        else
            this.CoWorkerObject.administrationRemindersSms = 'F'
    }

    onPayUnderVacation() {
        if (this.CoWorkerObject.isPayUnderVacation)
            this.CoWorkerObject.payUnderVacation = 'T'
        else
            this.CoWorkerObject.payUnderVacation = 'F'
    }

    ChangePicture(event) {
        //this.CoWorkerObject.picture = event;
    }

    getAllCoWorkerFields() {
        this.showSpinner = true;
        this.coWorkersService.GetAllFieldsForSingleCoworker<ResponseBase>(this.editableCoworkerID)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.CoWorkerObject = response.result;
                    this.CoWorkerObject.holidays = this.CoWorkerObject.holidays ? this.CoWorkerObject.holidays : 0;
                    this.CoWorkerObject.vacationHolidays = this.CoWorkerObject.vacationHolidays ? this.CoWorkerObject.vacationHolidays : 0;
                    this.CoWorkerObject.sickDays = this.CoWorkerObject.sickDays ? this.CoWorkerObject.sickDays : 0;
                    this.CoWorkerObject.timeoff = this.CoWorkerObject.timeoff ? this.CoWorkerObject.timeoff : 0;
                    this.CoWorkerObject.timeOffHoursPerWeek = this.CoWorkerObject.timeOffHoursPerWeek ? this.CoWorkerObject.timeOffHoursPerWeek : 0;
                    this.CoWorkerObject.multimediaTreasure = this.CoWorkerObject.multimediaTreasure ? this.CoWorkerObject.multimediaTreasure : 0;
                    this.CoWorkerObject.companyCarTax = this.CoWorkerObject.companyCarTax ? this.CoWorkerObject.companyCarTax : 0;
                    if (this.CoWorkerObject.cpr && this.CoWorkerObject.cpr.length >= 6 && !this.CoWorkerObject.birthday) {
                        var arrayObj = this.CoWorkerObject.cpr.split('-');
                        if (arrayObj.length > 0)
                            this.CoWorkerObject.birthday = new Date(moment(arrayObj[0], "DDMMYY")["_d"]);
                    }

                    this.objAddressEdit = {
                        'address': this.CoWorkerObject.address,
                        'city': this.CoWorkerObject.city,
                        'pinCode': this.CoWorkerObject.zipCode,
                        'position': this.CoWorkerObject.position,
                        'mapLocation': this.CoWorkerObject.mapLocation,
                        'IsEdit': true
                    }
                    var Id = this.authService.getUserId();
                    if (Id == this.CoWorkerObject.userId) {
                        this.CoWorkerObject.sameUser = true;
                        //this.savedata = true;
                    }
                    this.isLoad = true;
                    if (this.CoWorkerObject.inCaseOfAccident) {
                        this.InCaseOfAccidentDetails = JSON.parse(this.CoWorkerObject.inCaseOfAccident.replace(/'/g, '"'));
                    }
                    if (this.InCaseOfAccidentDetails) {
                    }
                    else if (this.InCaseOfAccidentDetails.length == 0)
                        this.AddInCaseOfAccidentDetail();
                    else {
                        this.InCaseOfAccidentDetails = [];
                        this.AddInCaseOfAccidentDetail();
                    }
                    this.CoWorkerObject.updateHolidays == 'T' ? this.CoWorkerObject.isUpdateHolidays = true : this.CoWorkerObject.isUpdateHolidays = false;
                    this.CoWorkerObject.updateVacationHolidays == 'T' ? this.CoWorkerObject.isUpdateVacationHolidays = true : this.CoWorkerObject.isSeniority = false;
                    this.CoWorkerObject.seniority == 'T' ? this.CoWorkerObject.isSeniority = true : this.CoWorkerObject.isUpdateVacationHolidays = false;
                    this.CoWorkerObject.printout == 'T' ? this.CoWorkerObject.isPrintout = true : this.CoWorkerObject.isPrintout = false;
                    this.CoWorkerObject.remindersSms == 'T' ? this.CoWorkerObject.isRemindersSms = true : this.CoWorkerObject.isRemindersSms = false;
                    this.CoWorkerObject.administrationBirthday == 'T' ? this.CoWorkerObject.isAdministrationBirthday = true : this.CoWorkerObject.isAdministrationBirthday = false;
                    this.CoWorkerObject.administrationRemindersSms == 'T' ? this.CoWorkerObject.isAdministrationRemindersSms = true : this.CoWorkerObject.isAdministrationRemindersSms = false;
                    this.CoWorkerObject.payUnderVacation == 'T' ? this.CoWorkerObject.isPayUnderVacation = true : this.CoWorkerObject.isPayUnderVacation = false;
                    this.showSpinner = false;
                    this.CoWorkerObject.isMultimediataxSetting == true ? this.CoWorkerObject.isMultimediataxSetting = true : this.CoWorkerObject.isMultimediataxSetting = false;
                    this.CoWorkerObject.responsibleManagerIdList = this.CoWorkerObject.responsibleManagerIds ? this.CoWorkerObject.responsibleManagerIds.split(','): [];
                    this.StaticCoWorkerObject = Object.assign({}, this.CoWorkerObject);
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }

    saveRemainingFields() {
        this.showSpinner = true;
        if (this.CoWorkerObject && this.CoWorkerObject.birthday)
            this.CoWorkerObject.birthday = new Date(moment(this.CoWorkerObject.birthday).format("YYYY-MM-DD"));
        this.CoWorkerObject.responsibleManagerIds = this.CoWorkerObject.responsibleManagerIdList.toString();
        this.coWorkersService.UpdateAllCoworkerFields<ResponseBase>(this.CoWorkerObject.id, this.CoWorkerObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.CoWorkerObject = response.result;
                    var Id = this.authService.getUserId();
                    if (Id == this.CoWorkerObject.userId) {
                        this.coWorkersService.SetUserIcon(this.CoWorkerObject.imagePath);
                    }
                    this.showSpinner = false;
                    this.storeFileFolderPathData();
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    this.getAllCoWorkerFields();
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    //this._router.navigateByUrl('coWorkers');
                }
            });
    }

    addUpdateCoworkerKey(action, index) {
        const actionType = action
        if (actionType == 'edit') {

        } else {

        }
        this.openCoworkerKeyModal(actionType, index)
    }

    openCoworkerKeyModal(actionType, index) {
        var actionDetail = {
            action: actionType,
            index: index,
            coworkerKeyList: this.coworkerKeyList
        }
        const dialogRef = this.dialog.open(KeyComponent, {
            height: "60%",
            width: "35%",
            data: actionDetail
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.CoWorkerObject.keysJson = result;
                this.Update();
            }
        });
    }

    getKeyType(id) {
        var name = "";
        var data = this.keyTypeList.filter(x => x.id == id);
        name = data[0].key;
        return name;
    }

    deleteKey(item, index) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteKey' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.coworkerKeyList.splice(index, 1);
                this.CoWorkerObject.keysJson = JSON.stringify(this.coworkerKeyList);
                setTimeout(() => {
                    this.Update();
                }, 500);
            }
        });
    }

    // addSendEmailPassword(id) {
    //     const dialogRef = this.dialog.open(SendEmailPasswordComponent, {
    //         width: '400px',
    //         data: id
    //     });

    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             this.CoWorkerObject.sendEmailId = null;
    //             this.CoWorkerObject.sendEmailPassword = null;
    //             this.CoWorkerObject.smtp = null;
    //             this.CoWorkerObject.smtpPort = null;
    //         }
    //     });
    // }

    addSendEmailPassword() {
        this.SendEmailPasswordData.sendEmailId = null;
        this.SendEmailPasswordData.sendEmailPassword = null;
        this.SendEmailPasswordData.smtp = null;
        this.SendEmailPasswordData.smtpPort = null;
        this.SendEmailPasswordData.isEdit = false;
        const dialogRef = this.dialog.open(SendEmailPasswordComponent, {
            width: '400px',
            data: this.SendEmailPasswordData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.CoWorkerObject.sendEmailId = result.sendEmailId;
                this.CoWorkerObject.sendEmailPassword = result.sendEmailPassword;
                this.CoWorkerObject.smtp = result.smtp;
                this.CoWorkerObject.smtpPort = result.smtpPort;
                // if (this.CoWorkerObject.id) {
                //     this.Update();
                // }
                // else{
                //     this.Add();
                // }
            }
        });
    }

    editSendEmailPassword() {
        this.SendEmailPasswordData.sendEmailId = this.CoWorkerObject.sendEmailId;
        this.SendEmailPasswordData.sendEmailPassword = "";
        this.SendEmailPasswordData.smtp = this.CoWorkerObject.smtp;
        this.SendEmailPasswordData.smtpPort = this.CoWorkerObject.smtpPort;
        this.SendEmailPasswordData.isEdit = true;
        const dialogRef = this.dialog.open(SendEmailPasswordComponent, {
            width: '400px',
            data: this.SendEmailPasswordData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.CoWorkerObject.sendEmailId = result.sendEmailId;
                this.CoWorkerObject.sendEmailPassword = result.sendEmailPassword;
                this.CoWorkerObject.smtp = result.smtp;
                this.CoWorkerObject.smtpPort = result.smtpPort;
            }
        });
    }

    deleteSendEmailPassword() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteEmail' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result)
                this.CoWorkerObject.sendEmailId = null;
                this.CoWorkerObject.sendEmailPassword = null;
                this.CoWorkerObject.smtp = null;
                this.CoWorkerObject.smtpPort = null;
            }
        });
    }

    getTermsOfEmploymentList() {
        this.showSpinner = true;
        this.termsOfEmploymentService.Get(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.termsOfEmploymentList = response.result;
                    // if(this.termsOfEmploymentList.length) {
                    //     this.termsOfEmploymentList.unshift({ "id": "0", "designation": "All", "valideFrom": null })
                    // }
                }
            },
            error: err => {
                this.termsOfEmploymentList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    gotoCoworker(id, $event) {
        if (this.coWokers) {
            this.tempCoworkerId = id;
            this.currentId = this.tempCoworkerId;
            this.getSetNextPreviousId();
            this.save($event);
            // if (this.editableCoworkerID != this.tempCoworkerId)
            //     this._router.navigate(['/coWorkers/edit', this.tempCoworkerId]);
        }
    }

    getSetNextPreviousId() {
        this.currentId = this.tempCoworkerId;
        var itemIndex = this.coWokers.map(function (o) { return o.id; }).indexOf(this.currentId);
        if (itemIndex > 0)
            this.previousId = this.coWokers[itemIndex - 1].id;
        else
            this.previousId = null;

        if (itemIndex != (this.coWokers.length - 1))
            this.nextId = this.coWokers[itemIndex + 1].id;
        else
            this.nextId = null;
    }

    getCoworkerEmploymentList() {
        this.showSpinner = true;
        this.coworkerEmploymentList = [];
        this.coWorkersService.GetCoworkerEmploymentList(this.requestCoworkerEmployment).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.coworkerEmploymentList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                }
            },
            error: err => {
                this.coworkerEmploymentList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
                this.showSpinner = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    receivePaginatonEvent(event) {
        this.requestCoworkerEmployment.Index = event.pageIndex;
        this.requestCoworkerEmployment.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.getCoworkerEmploymentList();
    }

    deleteCoworkerEmployment(id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteMessage' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.coWorkersService.DeleteCoworkerEmployment(id).subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.showSpinner = false;
                            this.getCoworkerEmploymentList();
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }

    editCoworkerEmployment(item) {
        const dialogRef = this.dialog.open(NewCoworkerEmploymentComponent, {
            width: "50%",
            data: {
                Header: 'Headers.DeleteMessage',
                data: item,
                coworkerID: this.editableCoworkerID,
                termsOfEmploymentList: this.termsOfEmploymentList
            }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = false;
                this.getCoworkerEmploymentList();
            }
        });
    }
    onUpdateSettingPayment() {
        if (this.CoWorkerObject.isMultimediataxSetting)
            this.CoWorkerObject.multimediataxSetting = 'T'
        else
            this.CoWorkerObject.multimediataxSetting = 'F'
    }

    getNotetabData() {
        this.showSpinner = true;
        this.coWorkersService.GetCoworkerFileInfo<any>(this.editableCoworkerID, ApiConstant.RequisitionNote).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.showSpinner = false;
                this.noteDescription = response.result.description;
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    openNoteDescription() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.noteDescription;

        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });


        dialogRef.afterClosed().subscribe(result => {
            this.showSpinner = true;
            var data = new CompanyInfoSetting();
            data.Description = "";
            if (result && result.Description) {
                data.Description = result.Description;
            }
            this.coWorkersService.AddUpdateCoworkerFileInfo<ResponseBase>(this.CoWorkerObject.id, ApiConstant.RequisitionNote, data)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.noteDescription = response.result.description;
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        });
    }

}

