export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header': 'Uniconta legitimationsoplysninger',
        },
        'FIELDS': {
            'UserName': 'Bruger navn',
            'Password': 'Password',
            'AccessId': 'Access id',
            'ComapnyName': 'Comapny name',
            'ComapnyId': 'Comapny id',
            'GenerateSalesOrders': 'Generer salgsordrer',
            'GenerateInvoices': 'Generer fakturaer',
            'GenerateTXTFile': 'Generer TXT File',
            'Path':'Sti/filnavn'
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Gemt Succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        },
        'Help':{
            'Note':'Brug kun skråstreg(/) i stiindstilling',
        }
    }
};

